@charset "UTF-8";

@import "../mixin";


.gl_header {
	height: 100px;
	body.is_header_static & {
		height: auto;
	}
	@include sp {
		height: 56px;
	}
	&--wrap {
		position:fixed;
		top:0;
		left:0;
		//position:relative;
		box-sizing: border-box;
		width: 100%;
		background-color: $color-green;
		z-index: 300;
		body.is_header_static & {
			position: relative;
		}
		@include sp {
			position: relative;
		}
	}
	&--inner {
		display: flex;
		justify-content: space-between;
		align-items:center;
		width: 100%;
		min-width: 1280px;
		max-width:1366px;
		margin: 0 auto;
		padding:0 30px;
		height:100px;
		@include sp{
			justify-content:flex-start;
			min-width:0;
			height:56px;
			padding:0 64px 0 24px;
		}
	}
	&--logo_tokyo {
		flex-shrink:0;
		width: 125px;
		height:32px;
		margin:0 24.7px 0 0;
		flex-shrink:1;
		@include sp{
			width:78px;
			height:20px;
			margin:0;
		}
	}
	&--logo_jobtry {
		width:100%;
		height:100%;
		&-link{
		width: 262px;
		height:42px;
			margin:0 24px 0 0;
			flex-shrink:1;
			@include sp{
				width:150px;
				height:24px;
				margin:0 0 0 18px;
			}
		}
	}
	&--logo_middle{
		display:none;
		width:130px;
		height:24px;
		margin:0 0 0 16px;
	}
	&--sp_btn{
		display:none;
		@include sp{
			//position:absolute;
			position:fixed;
			right:0;
			top:0;
			color:$color-text;
			display:block;
			width:56px;
			height:56px;
			padding-top:36px;
			background:$color-yellow;
			z-index:99;
			&-label{
				display:block;
				font-size:10px;
				font-weight:900;
				text-align:center;
			}
			&-label_close{
				font-size:10px;
				font-weight:900;
				text-align:center;
				display:none;
			}
			&-icon{
				position:absolute;
				left:50%;
				top:38%;
				width:18px;
				height:2px;
				background:$color-text;
				margin:0 0 0 -9px;
				transition:.3s linear;
				will-change:transform;
				&:before,&:after{
					position:absolute;
					left:0;
					top:0;
					content:'';
					width:18px;
					height:2px;
					background:$color-text;
					transition:.3s linear;
					will-change:transform;
				}
				&:before{
					transform:translate(0,-5px);
				}
				&:after{
					transform:translate(0,5px);
				}
			}
		}
	}
}

.gl_header_nav{
	display:flex;
	align-items:center;
	margin:0 0 0 auto;
	@include sp{
		position:fixed;
		display:block;
		left:0;
		top:0;
		background:#fff;
		width:100%;
		height:0;
		opacity:0;
		overflow:hidden;
		transition:.3s ease-out;
		will-change:transform;
	}
	&--inner{
		@extend .gl_header--inner;
		background:$color-green;
		display:none;
		@include sp{
			display:flex;
		}
	}
	&--logo_tokyo{
		@extend .gl_header--logo_tokyo;
	}
	&--logo_jobtry{
		@extend .gl_header--logo_jobtry;
		display:block!important;
		&-link{
			@extend .gl_header--logo_jobtry-link;
			display:block!important;
		}
	}
	&--ttl{
		display:none;
		@include sp{
			display:flex;
			align-items:center;
			justify-content:center;
			font-size:16px;
			font-weight:900;
			color:#fff;
			background:$color-green;
			height:56px;
		}
	}
	&--list{
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		align-items:stretch;
		flex-wrap:wrap;
		min-width:580px;
		height:42px;
		margin:0 24px 0 0;
		@include sp{
			display:block;
			min-width:0;
			width:100%;
			height:auto;
			margin:0;
		}
	}
	&--item{
		margin-right:16.6px;
		@include sp{
			margin:0;
			border-bottom:1px solid $color-lightgrey;
		}
	}
	&--link{
		display:block;
		line-height:1.5;
		color:#fff;
		font-size:12px;
		font-weight:900;
		width:100%;
		transition:.15s linear;
		&:hover{color:$color-yellow;}
		&:before{
			@include icon;
			content:$icon-arrow;
			font-size:12px;
			color:$color-yellow;
			vertical-align:middle;
			margin-right:5px;
		}
		@include sp{
			display:flex;
			align-items:center;
			justify-content:flex-start;
			color:$color-green;
			font-size:16px;
			width:100%;
			height:48px;
			padding:0 32px;
			&:hover{
				color:$color-green;
				background:$color-lightgrey;
			}
			&:before{
				color:$color-orange;
				margin-right:10px;
			}
		}
	}
	&--sns{
		display:none;
		@include sp{
			display:flex;
			justify-content:center;
			align-items:center;
			padding:0 32px;
		}
		@include sp5{
			padding:0 24px 24px;
		}
		&-facebook,&-twitter{
			display:flex;
			justify-content:center;
			width:40px;
			height:40px;
			border-radius:20px;
			& span{display:none;}
			&:before{
				@include icon;
				color:#fff;
			}
			@include sp5{
				width:35px;
				height:35px;
				border-radius:18px;
			}
		}
		&-facebook{
			align-items:flex-end;
			background:$color-facebook;
			&:before{
				content:$icon-facebook;
				font-size:33px;
			}
		}
		&-twitter{
			align-items:center;
			background:$color-x;
			&:before{
				content:$icon-x;
				font-size:24px;
			}
		}
		&-company{
			display:flex;
			align-items:center;
			justify-content:flex-start;
			color:$color-blue;
			font-size:15px;
			font-weight:900;
			&:before{
				@include icon;
				content:$icon-company;
				font-size:24px;
				margin-right:8px;
			}
			@include sp5{
				font-size:14px;
			}
		}
	}
	&--btn_program{
		color:#fff;
		display:flex;
		align-items:center;
		justify-content:center;
		font-size:16px;
		font-weight:900;
		background:$color-orange;
		width:192px;
		height:48px;
		border-radius:24px;
		border:1px solid #fff;
		box-shadow:0 3px 6px rgba(0,0,0,.16);
		margin-left:24px;
		transition:.15s linear;
		min-width:8em;
		flex-shrink:1;
		&:hover{
			background-color:#fff;
			color: $color-orange;
			border-color: $color-orange;
		}
		@include sp{
			width:calc(100% - 64px);
			height:56px;
			margin:24px 32px;
			box-shadow:none;
			border:none;
			border-radius:28px;
		}
	}
	&--btn_company{
		display:none;
		/*display:flex;*/
		align-items:center;
		justify-content:center;
		//position:absolute;
		position:fixed;
		right:0;
		top:116px;
		color:#fff;
		font-size:16px;
		font-weight:600;
		background:$color-blue;
		writing-mode:vertical-rl;
		width:56px;
		height:234px;
		border-radius:16px 0 0 16px;
		box-shadow:0 3px 6px rgba(0,0,0,.16);
		transition:.15s linear;
		& span{font-weight:inherit;}
		&:hover{filter:brightness(1.1);}
		&:before{
			@include icon;
			content:$icon-company;
			font-size:24px;
			margin-bottom:10px;
		}
		@include sp{
			/*display:flex;*/
			position:absolute;
			top:68px;
			font-size:10px;
			flex-direction:column;
			align-items:flex-start;
			writing-mode:horizontal-tb;
			width:115px;
			height:44px;
			padding-left:40px;
			border-radius:16px 0 0 16px;
			& span{
				display:block;
				font-size:12px;
				margin-bottom:2px;
			}
			&:before{
				font-size:22px;
				position:absolute;
				left:12px;
				top:10px;
				margin:0;
			}
		}
	}
}

//トップページ用
#index {
	& .gl_header_nav{
		&--btn_company{
			display:flex;
		}
	}
}

// SP
@include sp {
	//トップページ用
	#index{
		& .gl_header{
			&--logo_jobtry,
			&--logo_jobtry-link{
				display:none;
			}
			&--logo_middle{
				display:block;
			}
		}
	}
	//メニュー展開時
	.menuopen{
		overflow:hidden;
		& .gl_header--sp_btn{
			&-label{
				display:none;
				&_close{
					display:block;
				}
			}
			&-icon{
				transform:rotate(45deg);
				&:before{transform:translate(0,0) rotate(-90deg);}
				&:after{transform:translate(0,0) scale(0,1);opacity:0;}
			}
		}
		& .gl_header_nav{
			height:100%;
			opacity:1;
			overflow-y:scroll;
			&--btn_company{
				display:none;
			}
		}
	}
}
