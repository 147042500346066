@charset "UTF-8";

@import "../mixin";

.gl_contact {
	position:relative;
	padding: 60px 0;
	background:#fff;
	@include sp{
		padding:60px 0 40px;
	}
	&--ttl {
		font-weight: 900;
		font-size: 40px;
		color: $color-green;
		text-align: center;
		&:after{
			display:block;
			content:'';
			width:64px;
			height:2px;
			background:$color-orange;
			margin:34px auto 56px;
		}
		@include sp{
			font-size:30px;
			&:after{margin:30px auto 40px;}
		}
	}
	&--wrap{
		width:1000px;
		margin:0 auto;
		@include sp{
			width:100%;
		}
	}
	&--lead{
		font-size:16px;
		font-weight:500;
		margin-bottom:28px;
		@include sp{
			font-size:14px;
			line-height:1.4;
			text-align:center;
		}
	}
	&--office {
		font-weight: 900;
		font-size: 24px;
		margin-bottom:18px;
		@include sp{
			font-size:16px;
			text-align:center;
			margin-bottom:12px;
		}
	}
	&--tel {
		display:flex;
		align-items:center;
		font-weight: 900;
		font-size: 64px;
		color: $color-green;
		font-family:'Roboto';
		font-weight:700;
		letter-spacing:-0.03em;
		font-feature-settings:'palt';
		pointer-events:none;
		transform:scale(0.95,1);
		transform-origin:left center;
		margin:0 0 32px;
		&:before {
			display:block;
			@include icon;
			content: $icon-tel;
			font-size: 44px;
			margin:0 10px 0 0;
			transform:scale(1.05,1);
		}
		@include sp{
			pointer-events:initial;
			justify-content:center;
			font-size:48px;
			width:100%;
			margin-bottom:30px;
			transform:scale(.90,1);
			transform-origin:center center;
			&:before{
				font-size:32px;
			}
		}
	}
	&--addr {
		display:none;
		font-size: 14px;
		font-style:normal;
		line-height: 1.6;
		padding-bottom:20px;
	}
	&--ttl_sub01 {
		font-weight: 900;
		font-size: 20px;
		padding-bottom:24px;
		&:before {
			content: "●";
			color: $color-green;
		}
		@include sp{
			font-size:14px;
			padding-bottom:1em;
			text-align:center;
		}
	}
	&--txt{
		font-size:14px;
		line-height:1.4;
		font-weight:500;
		@include sp{
			font-size:12px;
			text-align:center;
		}
	}
	&--btn{
		display:flex;
		align-items:center;
		justify-content:center;
		color:$color-green;
		font-size:22px;
		line-height:1.2;
		font-weight:900;
		text-align:center;
		width:350px;
		height:84px;
		border:2px solid $color-green;
		border-radius:42px;
		margin:32px 0;
		transition:.15s linear;
		&:hover{
			color:#fff;
			background:$color-green;
		}
		@include sp{
			width:311px;
			height:75px;
			font-size:18px;
			margin:24px auto 0;
		}
	}
	&--ttl-map{
		display:none;
		@include sp{
			display:block;
			color:$color-green;
			font-size:20px;
			font-weight:900;
			text-align:center;
			margin:48px 0 30px;
		}
	}
	//埋め込みマップ部
	&--map {
		position:absolute;
		display: block;
		width:50%;
		height:392px;
		left:50%;
		top:190px;
		margin: 0;
		border: 0;
		@include sp{
			position:static;
			width:100%;
			height:342px;
		}
	}
	//アクセス情報
	&--access{
		width:1000px;
		padding:60px 0 80px;
		margin:0 auto;
		@include sp{
			width:100%;
			padding:30px 40px 60px;
		}
		&_ttl{
			font-size: 20px;
			font-weight:900;
			text-align:center;
			margin:0 0 32px;
			&:before{
				content:'●';
				color:$color-green;
			}
			@include sp{
				font-size:14px;
				margin:0 0 1em;
			}
		}
		&_list{
			display:flex;
			justify-content:space-between;
			align-items:stretch;
			@include sp{
				display:block;
			}
		}
		&_item{
			width:31%;
			@include sp{
				width:100%;
				margin-bottom:1em;
				&:last-of-type{margin-bottom:0;}
			}
		}
		&_sub{
			font-size:16px;
			font-weight:500;
			margin:0 0 1em;
			&:before{
				content:'●';
				color:$color-orange;
			}
			@include sp{
				font-size:12px;
				line-height:1.5;
				margin-bottom:0;
			}
		}
		&_txt{
			font-size:14px;
			line-height:21px;
			font-weight:500;
			text-align:justify;
			line-break:strict;
			@include sp{
				font-size:12px;
				line-height:1.5;
			}
		}
	}
	&--logo{
		display:flex;
		justify-content:center;
		align-items:center;
		&-tokyoto{
			width:166px;
			height:42px;
		}
		&-jobcenter{
			width:237px;
			height:38px;
			margin:0 76px;
		}
		&-middle_challenge{
			width:195px;
			height:36px;
		}
		@include sp{
			justify-content:space-between;
			padding: 0 24px;
			&-tokyoto{width:77px;height:20px;}
			&-jobcenter{
				width:110px;
				height:17px;
				margin:0;
			}
			&-middle_challenge{
				width:90px;
				height:17px;
			}
		}
	}
}

@media (max-width:425px) {
	.access {
		padding: 30px 0 0 0;
		&--ttl {
			font-size: 20px;
		}
		&--map {
			width: 100%;
			margin: 20px auto;
		}
		&--inner {
			width: 100%;
			padding: 15px;
			box-sizing: border-box;
		}
		&--l {
			float: none;
			width: 100%;
		}
		&--r {
			float: none;
			width: 100%;
			padding-top:20px;
		}
		&--office {
			line-height: 1.4;
			text-align: center;
			font-size: 16px;
		}
		&--tel {
      padding-left:30px;
      font-size: 30px;
      color: #46c65a;
      &:before {
        top: 7px;
        left: 0px;
        font-size: 24px;
      }
		}
		&--add {
			text-align: center;
			font-size: 12px;
			padding-bottom:20px;
		}
		&--ttl_sub01 {
			font-size: 16px;
			text-align: center;
			padding-bottom:20px;
		}
		&--ttl_sub02 {
			font-size: 12px;
		}
		&--txt {
			font-size: 12px;
			&-center {
				text-align: center;
			}
		}
		&--btm {
			text-align: center;
		}
		&--logo {
			margin:30px 0 0 0;
			&-01 {
				width:75px;
				padding-right:15px;
			}
			&-02 {
				width:120px;
				padding-right:15px;
			}
			&-03 {
				width: 130px;
			}
		}
	}
}
