@charset "UTF-8";

@import "mixin";

@import "common/gl-header";
@import "common/gl-footer";
@import "common/gl-contact";


/* body */
body {
	font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
	min-width: 1280px;
	color: #333;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}
.sp {
	display: none;
}
.pc {
	display: block;
}

@media (max-width:428px) {
	body {
		padding:0;
		min-width: inherit;
	}
	.sp {
		display: block;
	}
	.pc {
		display: none;
	}
}

//共通スタイル
.cmn{
	&--main{
		//padding:100px 0 0;
		padding:0;
		@include sp{
			//padding:56px 0 0;
			padding:0;
		}
	}
	&--section{
		color:#fff;
		background:$color-green;
		text-align:center;
		padding:60px 0;
		@include sp{
			width:100%;
			padding:50px 32px;
		}
	}
	&--intro{
		text-align:center;
		background:$color-green;
		padding:90px 0 60px;
		@include sp{
			padding:56px 0 40px;
		}
	}
	&--pagettl{
		color:$color-yellow;
		font-size:40px;
		font-weight:900;
		line-height:1.4;
		margin:0;
		@include sp{
			font-size:30px;
		}
	}
	&--pagedesc{
		color:#fff;
		font-size:16px;
		line-height:1.5;
		font-weight:500;
		margin:24px 0 0;
		@include sp{
			font-size:14px;
			margin-top:16px;
		}
	}
	&--ttl{
		position:relative;
		color:$color-yellow;
		font-size:40px;
		line-height:1.5;
		font-weight:900;
		text-align:center;
		margin:0 0 90px;
		@include sp{
			font-size:30px;
			margin-bottom:72px;
		}
		&:after{
			content:'';
			position:absolute;
			left:50%;
			bottom:-32px;
			width:64px;
			height:2px;
			background:$color-orange;
			margin-left:-32px;
			@include sp{
				bottom:-30px;
			}
		}
		&-no_border {
			margin-bottom: 32px;
			@include sp {
				margin-bottom: 20px;
			}
		}
		&-no_border:after {
			display: none;
		}
	}
	&--btn{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
		color:#fff;
		font-size:22px;
		font-weight:900;
		border:2px solid #fff;
		width:350px;
		height:84px;
		border-radius:42px;
		margin:0 auto;
		transition:.15s linear;
		@include sp{
			font-size:16px;
			width:auto;
			height:64px;
			border-radius:32px;
		}
		& span{
			display:block;
			font-size:14px;
			font-weight:500;
			margin:9px 0 0;
			@include sp{
				font-size:11px;
			}
		}
		&:hover{
      cursor: pointer;
      background:$color-orange;
    }
		&-orange{
			background:$color-orange;
			border-width:1px;
			box-shadow:0 3px 6px rgba(0,0,0,.16);
			& span{
				font-weight:900;
				margin:0 0 9px;
			}
			&:hover{
				background-color: #fff;
				color: $color-orange;
				border-color: $color-orange;
			}
		}
		&-green{
			color:$color-green;
			border-color:$color-green;
			&:hover{
				color:#fff;
				background:$color-green;
			}
		}
	}
}
