@charset "UTF-8";
.gl_header {
  height: 100px;
}

body.is_header_static .gl_header {
  height: auto;
}

@media screen and (max-width: 428px) {
  .gl_header {
    height: 56px;
  }
}

.gl_header--wrap {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #2B8D4E;
  z-index: 300;
}

body.is_header_static .gl_header--wrap {
  position: relative;
}

@media screen and (max-width: 428px) {
  .gl_header--wrap {
    position: relative;
  }
}

.gl_header--inner, .gl_header_nav--inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 1280px;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 30px;
  height: 100px;
}

@media screen and (max-width: 428px) {
  .gl_header--inner, .gl_header_nav--inner {
    justify-content: flex-start;
    min-width: 0;
    height: 56px;
    padding: 0 64px 0 24px;
  }
}

.gl_header--logo_tokyo, .gl_header_nav--logo_tokyo {
  flex-shrink: 0;
  width: 125px;
  height: 32px;
  margin: 0 24.7px 0 0;
  flex-shrink: 1;
}

@media screen and (max-width: 428px) {
  .gl_header--logo_tokyo, .gl_header_nav--logo_tokyo {
    width: 78px;
    height: 20px;
    margin: 0;
  }
}

.gl_header--logo_jobtry, .gl_header_nav--logo_jobtry {
  width: 100%;
  height: 100%;
}

.gl_header--logo_jobtry-link, .gl_header_nav--logo_jobtry-link {
  width: 262px;
  height: 42px;
  margin: 0 24px 0 0;
  flex-shrink: 1;
}

@media screen and (max-width: 428px) {
  .gl_header--logo_jobtry-link, .gl_header_nav--logo_jobtry-link {
    width: 150px;
    height: 24px;
    margin: 0 0 0 18px;
  }
}

.gl_header--logo_middle {
  display: none;
  width: 130px;
  height: 24px;
  margin: 0 0 0 16px;
}

.gl_header--sp_btn {
  display: none;
}

@media screen and (max-width: 428px) {
  .gl_header--sp_btn {
    position: fixed;
    right: 0;
    top: 0;
    color: #333;
    display: block;
    width: 56px;
    height: 56px;
    padding-top: 36px;
    background: #eddf21;
    z-index: 99;
  }
  .gl_header--sp_btn-label {
    display: block;
    font-size: 10px;
    font-weight: 900;
    text-align: center;
  }
  .gl_header--sp_btn-label_close {
    font-size: 10px;
    font-weight: 900;
    text-align: center;
    display: none;
  }
  .gl_header--sp_btn-icon {
    position: absolute;
    left: 50%;
    top: 38%;
    width: 18px;
    height: 2px;
    background: #333;
    margin: 0 0 0 -9px;
    transition: .3s linear;
    will-change: transform;
  }
  .gl_header--sp_btn-icon:before, .gl_header--sp_btn-icon:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 18px;
    height: 2px;
    background: #333;
    transition: .3s linear;
    will-change: transform;
  }
  .gl_header--sp_btn-icon:before {
    transform: translate(0, -5px);
  }
  .gl_header--sp_btn-icon:after {
    transform: translate(0, 5px);
  }
}

.gl_header_nav {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 428px) {
  .gl_header_nav {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: .3s ease-out;
    will-change: transform;
  }
}

.gl_header_nav--inner {
  background: #2B8D4E;
  display: none;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--inner {
    display: flex;
  }
}

.gl_header_nav--logo_jobtry {
  display: block !important;
}

.gl_header_nav--logo_jobtry-link {
  display: block !important;
}

.gl_header_nav--ttl {
  display: none;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 900;
    color: #fff;
    background: #2B8D4E;
    height: 56px;
  }
}

.gl_header_nav--list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-width: 580px;
  height: 42px;
  margin: 0 24px 0 0;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--list {
    display: block;
    min-width: 0;
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.gl_header_nav--item {
  margin-right: 16.6px;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--item {
    margin: 0;
    border-bottom: 1px solid #e9e9e9;
  }
}

.gl_header_nav--link {
  display: block;
  line-height: 1.5;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  width: 100%;
  transition: .15s linear;
}

.gl_header_nav--link:hover {
  color: #eddf21;
}

.gl_header_nav--link:before {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 12px;
  color: #eddf21;
  vertical-align: middle;
  margin-right: 5px;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2B8D4E;
    font-size: 16px;
    width: 100%;
    height: 48px;
    padding: 0 32px;
  }
  .gl_header_nav--link:hover {
    color: #2B8D4E;
    background: #e9e9e9;
  }
  .gl_header_nav--link:before {
    color: #ff9035;
    margin-right: 10px;
  }
}

.gl_header_nav--sns {
  display: none;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--sns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
  }
}

@media screen and (max-width: 320px) {
  .gl_header_nav--sns {
    padding: 0 24px 24px;
  }
}

.gl_header_nav--sns-facebook, .gl_header_nav--sns-twitter {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.gl_header_nav--sns-facebook span, .gl_header_nav--sns-twitter span {
  display: none;
}

.gl_header_nav--sns-facebook:before, .gl_header_nav--sns-twitter:before {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

@media screen and (max-width: 320px) {
  .gl_header_nav--sns-facebook, .gl_header_nav--sns-twitter {
    width: 35px;
    height: 35px;
    border-radius: 18px;
  }
}

.gl_header_nav--sns-facebook {
  align-items: flex-end;
  background: #1877f2;
}

.gl_header_nav--sns-facebook:before {
  content: "";
  font-size: 33px;
}

.gl_header_nav--sns-twitter {
  align-items: center;
  background: #000000;
}

.gl_header_nav--sns-twitter:before {
  content: "";
  font-size: 24px;
}

.gl_header_nav--sns-company {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #1270b2;
  font-size: 15px;
  font-weight: 900;
}

.gl_header_nav--sns-company:before {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 24px;
  margin-right: 8px;
}

@media screen and (max-width: 320px) {
  .gl_header_nav--sns-company {
    font-size: 14px;
  }
}

.gl_header_nav--btn_program {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 900;
  background: #ff9035;
  width: 192px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-left: 24px;
  transition: .15s linear;
  min-width: 8em;
  flex-shrink: 1;
}

.gl_header_nav--btn_program:hover {
  background-color: #fff;
  color: #ff9035;
  border-color: #ff9035;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--btn_program {
    width: calc(100% - 64px);
    height: 56px;
    margin: 24px 32px;
    box-shadow: none;
    border: none;
    border-radius: 28px;
  }
}

.gl_header_nav--btn_company {
  display: none;
  /*display:flex;*/
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 116px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: #1270b2;
  writing-mode: vertical-rl;
  width: 56px;
  height: 234px;
  border-radius: 16px 0 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: .15s linear;
}

.gl_header_nav--btn_company span {
  font-weight: inherit;
}

.gl_header_nav--btn_company:hover {
  filter: brightness(1.1);
}

.gl_header_nav--btn_company:before {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 24px;
  margin-bottom: 10px;
}

@media screen and (max-width: 428px) {
  .gl_header_nav--btn_company {
    /*display:flex;*/
    position: absolute;
    top: 68px;
    font-size: 10px;
    flex-direction: column;
    align-items: flex-start;
    writing-mode: horizontal-tb;
    width: 115px;
    height: 44px;
    padding-left: 40px;
    border-radius: 16px 0 0 16px;
  }
  .gl_header_nav--btn_company span {
    display: block;
    font-size: 12px;
    margin-bottom: 2px;
  }
  .gl_header_nav--btn_company:before {
    font-size: 22px;
    position: absolute;
    left: 12px;
    top: 10px;
    margin: 0;
  }
}

#index .gl_header_nav--btn_company {
  display: flex;
}

@media screen and (max-width: 428px) {
  #index .gl_header--logo_jobtry, #index .gl_header_nav--logo_jobtry, #index .gl_header--logo_jobtry-link, #index .gl_header_nav--logo_jobtry-link {
    display: none;
  }
  #index .gl_header--logo_middle {
    display: block;
  }
  .menuopen {
    overflow: hidden;
  }
  .menuopen .gl_header--sp_btn-label {
    display: none;
  }
  .menuopen .gl_header--sp_btn-label_close {
    display: block;
  }
  .menuopen .gl_header--sp_btn-icon {
    transform: rotate(45deg);
  }
  .menuopen .gl_header--sp_btn-icon:before {
    transform: translate(0, 0) rotate(-90deg);
  }
  .menuopen .gl_header--sp_btn-icon:after {
    transform: translate(0, 0) scale(0, 1);
    opacity: 0;
  }
  .menuopen .gl_header_nav {
    height: 100%;
    opacity: 1;
    overflow-y: scroll;
  }
  .menuopen .gl_header_nav--btn_company {
    display: none;
  }
}

.gl_footer {
  position: relative;
  color: #fff;
  background-color: #2B8D4E;
}

.gl_footer--wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1000px;
  padding: 40px 0;
  margin: 0 auto;
}

@media screen and (max-width: 428px) {
  .gl_footer--wrap {
    display: block;
    padding: 32px 24px 16px;
  }
}

.gl_footer--logo {
  width: 240px;
  height: 103px;
  margin: 0 80px 0 0;
}

@media screen and (max-width: 428px) {
  .gl_footer--logo {
    display: block;
    width: 185px;
    height: 80px;
    margin: 0 auto 24px;
  }
}

.gl_footer--list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 400px;
  height: 180px;
  margin: 0 auto 0 0;
}

@media screen and (max-width: 428px) {
  .gl_footer--list {
    width: 100%;
    height: auto;
    flex-wrap: nowrap;
  }
}

.gl_footer--item {
  margin: 0 64px 20px 0;
}

.gl_footer--link {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.gl_footer--link:before {
  content: '- ';
}

.gl_footer--link:hover {
  text-decoration: underline;
}

.gl_footer--sub {
  color: #333;
  width: 100%;
  padding: 0;
  background: #e9e9e9;
}

@media screen and (max-width: 428px) {
  .gl_footer--sub {
    padding: 0 24px;
  }
}

.gl_footer--sub_list {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px 0 23px;
}

@media screen and (max-width: 428px) {
  .gl_footer--sub_list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.gl_footer--sub_item {
  margin-right: 40px;
}

@media screen and (max-width: 428px) {
  .gl_footer--sub_item {
    width: 46%;
    margin-right: 0;
  }
}

@media screen and (max-width: 428px) {
  .gl_footer--sub_item:nth-child(n+3) {
    margin-top: 8px;
  }
}

.gl_footer--sub_link {
  font-size: 12px;
  font-weight: 500;
  transition: .15s linear;
}

.gl_footer--sub_link:hover {
  color: #2B8D4E;
}

.gl_footer--sub_link:before {
  content: '- ';
}

.gl_footer--r_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 40px;
}

@media screen and (max-width: 428px) {
  .gl_footer--r_wrapper {
    align-items: center;
    gap: 0;
  }
}

.gl_footer--sns {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 428px) {
  .gl_footer--sns {
    margin: 16px 0;
  }
}

.gl_footer--sns_item:not(:first-child) {
  margin-left: 24px;
}

.gl_footer--sns_link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: .15s linear;
  z-index: 1;
  /*&:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        border-radius:50%;
        transform:scale(0,0);
        opacity:1;
        background:#fff;
        transition:.3s ease-out;
      }*/
}

.gl_footer--sns_link span {
  display: none;
}

.gl_footer--sns_link:hover {
  transform: scale(1.2, 1.2);
}

.gl_footer--sns_link:hover:before {
  color: #fff;
}

.gl_footer--sns_link:before {
  position: relative;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2B8D4E;
  transition: .15s linear;
  z-index: 1;
}

.gl_footer--sns_link-facebook {
  align-items: flex-end;
}

.gl_footer--sns_link-facebook:hover {
  background: #1877f2;
}

.gl_footer--sns_link-facebook:before {
  content: "";
  font-size: 32px;
}

.gl_footer--sns_link-twitter:hover {
  background: #000000;
}

.gl_footer--sns_link-twitter:before {
  content: "";
  font-size: 24px;
}

.gl_footer--sns_link-instagram {
  background-position: center;
  background-size: 66px 66px;
}

.gl_footer--sns_link-instagram:hover {
  background-image: url("../images/instagram-hover_bg.png");
}

.gl_footer--sns_link-instagram:before {
  content: "";
  font-size: 22px;
}

.gl_footer--banner_link {
  display: block;
  width: 240px;
}

.gl_footer--banner_img {
  display: block;
  width: 100%;
}

.gl_footer--copyright {
  background-color: #fff;
  text-align: center;
  padding: 30px 0 60px;
}

.gl_footer--copyright_txt {
  text-align: center;
  font-size: 10px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 24px;
}

.gl_footer--copyright_copy {
  color: #2B8D4E;
  font-size: 12px;
  font-weight: 500;
}

.gl_footer--pagetop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #999;
  transition: .15s linear;
}

.gl_footer--pagetop:hover {
  background: #ff9035;
}

.gl_footer--pagetop:after {
  font-size: 16px;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transform: rotate(-90deg);
}

@media screen and (max-width: 428px) {
  .gl_footer--pagetop {
    display: flex;
  }
}

.gl_contact {
  position: relative;
  padding: 60px 0;
  background: #fff;
}

@media screen and (max-width: 428px) {
  .gl_contact {
    padding: 60px 0 40px;
  }
}

.gl_contact--ttl {
  font-weight: 900;
  font-size: 40px;
  color: #2B8D4E;
  text-align: center;
}

.gl_contact--ttl:after {
  display: block;
  content: '';
  width: 64px;
  height: 2px;
  background: #ff9035;
  margin: 34px auto 56px;
}

@media screen and (max-width: 428px) {
  .gl_contact--ttl {
    font-size: 30px;
  }
  .gl_contact--ttl:after {
    margin: 30px auto 40px;
  }
}

.gl_contact--wrap {
  width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 428px) {
  .gl_contact--wrap {
    width: 100%;
  }
}

.gl_contact--lead {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 28px;
}

@media screen and (max-width: 428px) {
  .gl_contact--lead {
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
  }
}

.gl_contact--office {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 18px;
}

@media screen and (max-width: 428px) {
  .gl_contact--office {
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
  }
}

.gl_contact--tel {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 64px;
  color: #2B8D4E;
  font-family: 'Roboto';
  font-weight: 700;
  letter-spacing: -0.03em;
  font-feature-settings: 'palt';
  pointer-events: none;
  transform: scale(0.95, 1);
  transform-origin: left center;
  margin: 0 0 32px;
}

.gl_contact--tel:before {
  display: block;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 44px;
  margin: 0 10px 0 0;
  transform: scale(1.05, 1);
}

@media screen and (max-width: 428px) {
  .gl_contact--tel {
    pointer-events: initial;
    justify-content: center;
    font-size: 48px;
    width: 100%;
    margin-bottom: 30px;
    transform: scale(0.9, 1);
    transform-origin: center center;
  }
  .gl_contact--tel:before {
    font-size: 32px;
  }
}

.gl_contact--addr {
  display: none;
  font-size: 14px;
  font-style: normal;
  line-height: 1.6;
  padding-bottom: 20px;
}

.gl_contact--ttl_sub01 {
  font-weight: 900;
  font-size: 20px;
  padding-bottom: 24px;
}

.gl_contact--ttl_sub01:before {
  content: "●";
  color: #2B8D4E;
}

@media screen and (max-width: 428px) {
  .gl_contact--ttl_sub01 {
    font-size: 14px;
    padding-bottom: 1em;
    text-align: center;
  }
}

.gl_contact--txt {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
}

@media screen and (max-width: 428px) {
  .gl_contact--txt {
    font-size: 12px;
    text-align: center;
  }
}

.gl_contact--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2B8D4E;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 900;
  text-align: center;
  width: 350px;
  height: 84px;
  border: 2px solid #2B8D4E;
  border-radius: 42px;
  margin: 32px 0;
  transition: .15s linear;
}

.gl_contact--btn:hover {
  color: #fff;
  background: #2B8D4E;
}

@media screen and (max-width: 428px) {
  .gl_contact--btn {
    width: 311px;
    height: 75px;
    font-size: 18px;
    margin: 24px auto 0;
  }
}

.gl_contact--ttl-map {
  display: none;
}

@media screen and (max-width: 428px) {
  .gl_contact--ttl-map {
    display: block;
    color: #2B8D4E;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    margin: 48px 0 30px;
  }
}

.gl_contact--map {
  position: absolute;
  display: block;
  width: 50%;
  height: 392px;
  left: 50%;
  top: 190px;
  margin: 0;
  border: 0;
}

@media screen and (max-width: 428px) {
  .gl_contact--map {
    position: static;
    width: 100%;
    height: 342px;
  }
}

.gl_contact--access {
  width: 1000px;
  padding: 60px 0 80px;
  margin: 0 auto;
}

@media screen and (max-width: 428px) {
  .gl_contact--access {
    width: 100%;
    padding: 30px 40px 60px;
  }
}

.gl_contact--access_ttl {
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  margin: 0 0 32px;
}

.gl_contact--access_ttl:before {
  content: '●';
  color: #2B8D4E;
}

@media screen and (max-width: 428px) {
  .gl_contact--access_ttl {
    font-size: 14px;
    margin: 0 0 1em;
  }
}

.gl_contact--access_list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

@media screen and (max-width: 428px) {
  .gl_contact--access_list {
    display: block;
  }
}

.gl_contact--access_item {
  width: 31%;
}

@media screen and (max-width: 428px) {
  .gl_contact--access_item {
    width: 100%;
    margin-bottom: 1em;
  }
  .gl_contact--access_item:last-of-type {
    margin-bottom: 0;
  }
}

.gl_contact--access_sub {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 1em;
}

.gl_contact--access_sub:before {
  content: '●';
  color: #ff9035;
}

@media screen and (max-width: 428px) {
  .gl_contact--access_sub {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.gl_contact--access_txt {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-align: justify;
  line-break: strict;
}

@media screen and (max-width: 428px) {
  .gl_contact--access_txt {
    font-size: 12px;
    line-height: 1.5;
  }
}

.gl_contact--logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gl_contact--logo-tokyoto {
  width: 166px;
  height: 42px;
}

.gl_contact--logo-jobcenter {
  width: 237px;
  height: 38px;
  margin: 0 76px;
}

.gl_contact--logo-middle_challenge {
  width: 195px;
  height: 36px;
}

@media screen and (max-width: 428px) {
  .gl_contact--logo {
    justify-content: space-between;
    padding: 0 24px;
  }
  .gl_contact--logo-tokyoto {
    width: 77px;
    height: 20px;
  }
  .gl_contact--logo-jobcenter {
    width: 110px;
    height: 17px;
    margin: 0;
  }
  .gl_contact--logo-middle_challenge {
    width: 90px;
    height: 17px;
  }
}

@media (max-width: 425px) {
  .access {
    padding: 30px 0 0 0;
  }
  .access--ttl {
    font-size: 20px;
  }
  .access--map {
    width: 100%;
    margin: 20px auto;
  }
  .access--inner {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .access--l {
    float: none;
    width: 100%;
  }
  .access--r {
    float: none;
    width: 100%;
    padding-top: 20px;
  }
  .access--office {
    line-height: 1.4;
    text-align: center;
    font-size: 16px;
  }
  .access--tel {
    padding-left: 30px;
    font-size: 30px;
    color: #46c65a;
  }
  .access--tel:before {
    top: 7px;
    left: 0px;
    font-size: 24px;
  }
  .access--add {
    text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
  }
  .access--ttl_sub01 {
    font-size: 16px;
    text-align: center;
    padding-bottom: 20px;
  }
  .access--ttl_sub02 {
    font-size: 12px;
  }
  .access--txt {
    font-size: 12px;
  }
  .access--txt-center {
    text-align: center;
  }
  .access--btm {
    text-align: center;
  }
  .access--logo {
    margin: 30px 0 0 0;
  }
  .access--logo-01 {
    width: 75px;
    padding-right: 15px;
  }
  .access--logo-02 {
    width: 120px;
    padding-right: 15px;
  }
  .access--logo-03 {
    width: 130px;
  }
}

/* body */
body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  min-width: 1280px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

.sp {
  display: none;
}

.pc {
  display: block;
}

@media (max-width: 428px) {
  body {
    padding: 0;
    min-width: inherit;
  }
  .sp {
    display: block;
  }
  .pc {
    display: none;
  }
}

.cmn--main {
  padding: 0;
}

@media screen and (max-width: 428px) {
  .cmn--main {
    padding: 0;
  }
}

.cmn--section {
  color: #fff;
  background: #2B8D4E;
  text-align: center;
  padding: 60px 0;
}

@media screen and (max-width: 428px) {
  .cmn--section {
    width: 100%;
    padding: 50px 32px;
  }
}

.cmn--intro {
  text-align: center;
  background: #2B8D4E;
  padding: 90px 0 60px;
}

@media screen and (max-width: 428px) {
  .cmn--intro {
    padding: 56px 0 40px;
  }
}

.cmn--pagettl {
  color: #eddf21;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.4;
  margin: 0;
}

@media screen and (max-width: 428px) {
  .cmn--pagettl {
    font-size: 30px;
  }
}

.cmn--pagedesc {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 24px 0 0;
}

@media screen and (max-width: 428px) {
  .cmn--pagedesc {
    font-size: 14px;
    margin-top: 16px;
  }
}

.cmn--ttl {
  position: relative;
  color: #eddf21;
  font-size: 40px;
  line-height: 1.5;
  font-weight: 900;
  text-align: center;
  margin: 0 0 90px;
}

@media screen and (max-width: 428px) {
  .cmn--ttl {
    font-size: 30px;
    margin-bottom: 72px;
  }
}

.cmn--ttl:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -32px;
  width: 64px;
  height: 2px;
  background: #ff9035;
  margin-left: -32px;
}

@media screen and (max-width: 428px) {
  .cmn--ttl:after {
    bottom: -30px;
  }
}

.cmn--ttl-no_border {
  margin-bottom: 32px;
}

@media screen and (max-width: 428px) {
  .cmn--ttl-no_border {
    margin-bottom: 20px;
  }
}

.cmn--ttl-no_border:after {
  display: none;
}

.cmn--btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  font-weight: 900;
  border: 2px solid #fff;
  width: 350px;
  height: 84px;
  border-radius: 42px;
  margin: 0 auto;
  transition: .15s linear;
}

@media screen and (max-width: 428px) {
  .cmn--btn {
    font-size: 16px;
    width: auto;
    height: 64px;
    border-radius: 32px;
  }
}

.cmn--btn span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 9px 0 0;
}

@media screen and (max-width: 428px) {
  .cmn--btn span {
    font-size: 11px;
  }
}

.cmn--btn:hover {
  cursor: pointer;
  background: #ff9035;
}

.cmn--btn-orange {
  background: #ff9035;
  border-width: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.cmn--btn-orange span {
  font-weight: 900;
  margin: 0 0 9px;
}

.cmn--btn-orange:hover {
  background-color: #fff;
  color: #ff9035;
  border-color: #ff9035;
}

.cmn--btn-green {
  color: #2B8D4E;
  border-color: #2B8D4E;
}

.cmn--btn-green:hover {
  color: #fff;
  background: #2B8D4E;
}
