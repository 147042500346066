@charset "UTF-8";

// カラー
$color-green: #2B8D4E;
$color-lightgreen:#00c649;
$color-text: #333;
$color-darkgrey: #666;
$color-grey: #999;
$color-midgrey:#ccc;
$color-lightgrey:#e9e9e9;
$color-cream:#f6f9e8;
$color-yellow:#eddf21;
$color-blue: #1270b2;
$color-lightblue: #2d8cff;
$color-orange: #ff9035;
$color-facebook: #1877f2;
$color-twitter: #1d9bf0;
$color-zoom: #2d8cff;
$color-paleblue: #DFE8EB;
$color-red:#ff0000;
$color-x: #000000;

// フォントウェイト
$fw-regular: 400;
$fw-bold: 700;
$fw-black: 900;

// アイコン
$icon-tel:"\e900";//電話番号
$icon-arrow:"\e90b";//右向き矢印
$icon-facebook:"\e901";//facebook
$icon-twitter:"\e902";//twitter
$icon-instagram:"\ea92";//Instagram
$icon-company:"\e903";//求人企業アイコン
$icon-point01:"\e904";//ポイント1
$icon-point02:"\e905";//ポイント2
$icon-point03:"\e906";//ポイント3
$icon-point04:"\e907";//ポイント4
$icon-tail:"\e908";//吹き出し尻尾
$icon-try:"\e909";//トライロゴタイプ
$icon-voice:"\e90a";//受講者アイコン
$icon-device:"\e90c";//デバイスアイコン
$icon-video:"\e90d";
$icon-x:"\e90e";//X

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

// SP,TBブレークポイント
@mixin sp5{
	@media screen and (max-width:320px) {
		@content;
	}
}
@mixin sp {
	@media screen and (max-width:428px) {
		@content;
	}
}
@mixin tb{
	@media screen and (max-width:768px) {
		@content;
	}
}

// IE除け
@mixin ie{
	@media screen and (-ms-high-contrast: none){
		@content;
	}
}

// アイコンフォント
@mixin icon {
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// CSS min,max関数のための再定義
// SASS内関数
@function _min($numbers...) {
	@return min($numbers...);
}
@function _max($numbers...) {
	@return min($numbers...);
}
// CSS関数
@function min($values...) {
	@return unquote('min(#{$values})');
}
@function max($values...) {
	@return unquote('max(#{$values})');
}
