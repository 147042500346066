@charset "UTF-8";
@import "../mixin";

.gl_footer {
  position: relative;
  color:#fff;
  background-color:$color-green;
  &--wrap{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    width:100%;
    max-width:1000px;
    padding:40px 0;
    margin:0 auto;
    @include sp{
      display:block;
      padding:32px 24px 16px;
    }
  }
  &--logo{
    width:240px;
    height:103px;
    margin:0 80px 0 0;
    @include sp{
      display:block;
      width:185px;
      height:80px;
      margin:0 auto 24px;
    }
  }
  &--list {
    display: flex;
    flex-direction:column;
    align-items:stretch;
    flex-wrap:wrap;
    justify-content: flex-start;
    width:400px;
    height:180px;
    margin:0 auto 0 0;
    @include sp{
      width:100%;
      height:auto;
      flex-wrap:nowrap;
    }
  }
  &--item {
    margin:0 64px 20px 0;
  }
  &--link {
    display: block;
    font-size: 14px;
    font-weight:500;
    &:before{
      content:'- ';
    }
    &:hover {
      text-decoration: underline;
    }
  }
  //プライバシー／求人企業
  &--sub{
    color:$color-text;
    width:100%;
    padding:0;
    background:$color-lightgrey;
    @include sp{padding:0 24px;}
    &_list{
      display:flex;
      max-width:1000px;
      margin:0 auto;
      padding:16px 0 23px;
      @include sp {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    &_item{
      margin-right:40px;
      @include sp {
        width: 46%;
        margin-right: 0;
      }
      &:nth-child(n+3) {
        @include sp {
          margin-top: 8px;
        }
      }
    }
    &_link{
      font-size:12px;
      font-weight:500;
      transition:.15s linear;
      &:hover{
        color:$color-green;
      }
      &:before{
        content:'- ';
      }
    }
  }
  &--r_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 40px;
    @include sp {
      align-items: center;
      gap:0;
    }
  }
  //SNSボタン
  &--sns{
    display:flex;
    align-items:center;
    justify-content: center;
    @include sp {
      margin: 16px 0;
    }
    &_item{
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
    &_link{
      position:relative;
      display:flex;
      justify-content:center;
      align-items:center;
      background:#fff;
      width:40px;
      height:40px;
      border-radius:20px;
      transition:.15s linear;
      z-index:1;
      & span{
        display:none;
      }
      &:hover{
        transform:scale(1.2,1.2);
        &:before{color:#fff;}
        //&:after{transform:scale(1.5,1.5);opacity:0;}
      }
      &:before{
        position:relative;
        @include icon;
        color:$color-green;
        transition:.15s linear;
        z-index:1;
      }
      /*&:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        border-radius:50%;
        transform:scale(0,0);
        opacity:1;
        background:#fff;
        transition:.3s ease-out;
      }*/
      &-facebook{
        align-items:flex-end;
        &:hover{
          background:$color-facebook;
        }
        &:before{
          content:$icon-facebook;
          font-size:32px;
        }
      }
      &-twitter{
        &:hover{
          background:$color-x;
        }
        &:before{
          content:$icon-x;
          font-size:24px;
        }
      }
      &-instagram {
        background-position: center;
        background-size: 66px 66px;
        &:hover{
          background-image: url('../images/instagram-hover_bg.png');
        }
        &:before{
          content:$icon-instagram;
          font-size:22px;
        }
      }
    }
  }
  &--banner_link {
    display: block;
    width: 240px;
  }
  &--banner_img {
    display: block;
    width: 100%;
  }
  //コピーライト
  &--copyright {
    background-color: #fff;
    text-align:center;
    padding: 30px 0 60px;
    &_txt {
      text-align: center;
      font-size: 10px;
      line-height: 1.6;
      color: #666;
      margin-bottom:24px;
    }
    &_copy{
      color:$color-green;
      font-size:12px;
      font-weight:500;
    }
  }
  //ページトップリンク
  &--pagetop{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:48px;
    background:$color-grey;
    transition:.15s linear;
    &:hover{
      background:$color-orange;
    }
    &:after{
      font-size:16px;
      @include icon;
      content:$icon-arrow;
      transform:rotate(-90deg);
    }
    @include sp{
      display:flex;
    }
  }

}
